.container {
  display: flex;
}

.outlet {
  flex: 1;
  min-height: calc(100vh - var(--navbar-height));
  max-height: calc(100vh - var(--navbar-height));
  overflow-y: auto;
  min-width: 0;
  padding: 35px 0 0;
  background-color: white;
  display: flex;
  justify-content: center;
}
