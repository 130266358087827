/* root styles */
:root {
  --bs-border-radius: 8px;
}
.modal {
  --bs-modal-inner-border-radius: 16px;
}

/* button styles */
.btn.btn-primary {
  background-color: var(--green-600);
  border-color: var(--green-600);
}
.btn.btn-primary:hover {
  background-color: var(--success-600);
  border-color: var(--success-600);
}
.btn.btn-primary:focus,
.btn.btn-primary.focus {
  box-shadow: 0 0 0 2px var(--green-200);
}
.btn.btn-primary.disabled,
.btn.btn-primary:disabled {
  color: white;
  background-color: var(--gray-200);
  border-color: var(--gray-200);
  opacity: 1;
}
.btn.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-primary:not(:disabled):not(.disabled).active {
  background-color: var(--success-600);
  border-color: var(--success-600);
}
.show > .btn.btn-primary.dropdown-toggle {
  background-color: var(--green-600);
  border-color: var(--green-600);
}
.show > .btn.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px var(--green-200);
}
.btn.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 2px var(--green-200);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--green-600) !important;
  background-color: var(--green-100);
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--success-600) !important;
  background-color: var(--green-100);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: white !important;
  background-color: var(--gray-200);
}

.btn.btn-outline-primary {
  background-color: transparent;
  border-color: var(--green-600);
  color: var(--green-600);
}
.btn.btn-outline-primary:hover {
  background-color: var(--green-600);
  border-color: var(--green-600);
  color: white;
}
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary.focus {
  box-shadow: 0 0 0 2px var(--green-200);
}
.btn.btn-primary.disabled,
.btn.btn-primary:disabled {
  color: white;
  background-color: var(--gray-200);
  border-color: var(--gray-200);
  opacity: 1;
}
.btn.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-primary:not(:disabled):not(.disabled).active {
  background-color: var(--success-600);
  border-color: var(--success-600);
}
.show > .btn.btn-outline-primary.dropdown-toggle {
  background-color: transparent;
  border-color: var(--green-600);
  color: var(--green-600);
}
.show > .btn.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px var(--green-200);
}
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn.btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 2px var(--green-200);
}

/* border style */
.border-gray-300 {
  border-color: var(--gray-300) !important;
}

/* input style */
:focus-visible {
  outline: var(--green-600) auto 1px;
}

/* Modal */
.modal-content {
  border: unset;
}
.closed-circle .btn-close {
  border-radius: 999rem;
  background-color: var(--gray-300);
}
