.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navbar-height);
  padding: 0 var(--navbar-padding-x);
  background-color: var(--navbar-bg);
  border-bottom: 1px solid var(--layout-border-color);
}

.leftContainer {
  display: flex;
  align-items: center;
}

.rightContainer {
  display: flex;
  align-items: center;
}
