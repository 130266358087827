.toggle {
  height: 32px;
  width: 32px;
}

.userImg {
  border-radius: 50%;
  height: 100%;
  width: 100%;

  /* for user thumbnail display */
  background-size: cover;
  background-position: center;

  /* for username abbreviation display */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: #0177ff;
}

.menu {
  margin-top: 0.5em;
}
