.wrapper {
  padding: var(--sidebar-padding-y) 0;
  border-right: 1px solid var(--layout-border-color);
  background-color: var(--sidebar-bg);
}

.container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  color: var(--sidebar-color);
  position: relative;
}
.container::before {
  content: '';
  width: 3px;
  height: var(--menuItemHeight, '47px');
  margin-top: calc(var(--menuItemIndex, 0) * var(--menuItemHeight, 47));
  position: absolute;
  left: 0;
  top: 0;
  background-color: #089a22;
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 31px;
  white-space: pre-wrap;
  font-weight: 500;
  position: relative;
}

.menuItemIcon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: brightness(0);
}
.menuItemIcon.active {
  filter: none;
}

.menuItemLabel {
  color: var(--gray-700);
  font-size: 18px;
}
.menuItemLabel.active {
  color: var(--green-700);
}

@media only screen and (max-width: 768px) {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 40vw;
    height: 100vh;
    padding: 0;
    z-index: 999999;
    transition: left 300ms ease-in-out;
  }
  .wrapper:not(.open) {
    left: -82vw;
  }
  .sideBarLogoImage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px 40px;
  }
  .sideBarLogoImage img {
    width: 50%;
    max-width: 110px;
  }
}

@media only screen and (max-width: 480px) {
  .wrapper {
    width: 70vw;
  }
}
