:root {
  --layout-border-color: #e5e7ea;
  --navbar-height: 75px;
  --navbar-padding-x: 25px;
  --navbar-bg: #ffffff;
  --sidebar-padding-y: 10px;
  --sidebar-bg: #ffffff;
  --sidebar-color: #212121;
  --sidebar-color-light: #a4acba;
  --body-bg: #f9fafb;
  --body-bg-darker: #e5e7ea;
  --popup-bg-light: rgba(249, 250, 251, 0.64);
  --popup-bg-dark: rgba(0, 0, 0, 0.5);
  /* AZURE */
  --azure-100: #e1f1ff;
  --azure-600: #1570ef;
  /* DANGER */
  --danger-600: #d92d20;
  --danger-700: #b42318;
  --danger-800: #912018;
  /* SUCCESS */
  --success-400: #3ccb7f;
  --success-500: #16b364;
  --success-600: #099250;
  /* GRAY */
  --gray-100: #f8fafc;
  --gray-200: #eaecf0;
  --gray-300: #d0d5dd;
  --gray-400: #98a2b3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  /* BASE */
  --base-black: #170f0e;
  /* ORANGE */
  --orange-600: #e04f16;
  /* GREEN */
  --green-100: #dfffd3;
  --green-200: #aaeaaa;
  --green-300: #73e2a3;
  --green-400: #3ccb7f;
  --green-500: #41d041;
  --green-600: #02c602;
  --green-700: #00b800;
  --green-900: #164716;
}

/*************************************************************************************** Custom Defaults **/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}

.underline {
  text-decoration: underline;
}

.isEditing {
  color: var(--danger-600);
  font-size: 0.9rem;
}

.disabled {
  opacity: 0.2;
}

.small-grey-500-text {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 19px;
  color: var(--gray-500);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.heading {
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
}

.large-heading {
  margin: 0;
  font-weight: 600;
  font-size: 1.2em;
  text-transform: capitalize;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--gray-200);
}
.text-gray-200 {
  color: var(--gray-200);
}
.text-gray-300 {
  color: var(--gray-300);
}
.text-gray-400 {
  color: var(--gray-400);
}
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
}
.cursor-pointer {
  cursor: pointer;
}
.w-button-icon-right {
  margin-left: 10px;
}
.w-button-icon-left {
  margin-right: 10px;
}
.form-wrapper {
  padding-top: 24px;
}
.form-wrapper-title {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: #333;
}
.form-wrapper-sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: var(--gray-500);
}
.form-wrapper-box {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px 32px 16px;
  background-color: white;
  border-radius: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2rem;
  color: var(--base-black);
}
.default-text-input-style {
  font-size: 1.7rem;
  font-weight: 600;
}
.defaultWrapper {
  padding: 28px;
  background-color: #ffffff;
  border-radius: 8px;
}
.defaultImageThumbnail {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--body-bg-darker);
}
.clearButton {
  color: var(--danger-600);
  display: flex;
  align-self: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}
.clearButton:hover,
.clearButton:focus {
  color: var(--danger-700);
}
.noData {
  padding: 100px;
  display: flex;
  flex: 1;
  justify-content: center;
  color: var(--gray-500);
}

/** Default css settings for a full page padded container with header and title */
.pageContainer {
  width: 100%;
  padding: 0 30px 0 30px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
  }

  .title {
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
  }

  .subTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3125rem;
  }
}

/** Default 2 Column Card **/
.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  & .cardContainer__item {
    background-color: var(--gray-100);
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;

    & .cardContainer__row {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      padding-top: 10px;

      & .cardContainer__item__heading {
        flex: 30%;
        padding-left: 5px;
        color: var(--gray-500);
      }

      & .cardContainer__item__content {
        flex: 70%;
      }
    }
  }
}

.default {
  color: var(--gray-500);
  font-weight: 500;
  font-size: 0.92em;
}

.postTitle {
  color: var(--gray-600);
  font-weight: 700;
  font-size: 1.2em;
}

.tags {
  color: var(--gray-600);
  font-weight: 700;
  font-size: 1em;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.wyskSummary {
  color: var(--gray-500);
  font-weight: 400;
  font-size: 0.92em;
}

.pubDate {
  color: var(--gray-600);
  font-weight: 700;
  font-size: 1.1em;
}

.postType {
  color: var(--gray-600);
  font-weight: 700;
  font-size: 1.1em;
}

/** Default vertical card design to display post information **/
.postVerticalCard {
  display: flex;
  background-color: var(--gray-100);
  border-radius: 8px;
  padding: 10px;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .cardContainer__column {
    display: flex;
    flex-direction: column;
  }

  .cardContainer__row {
    display: flex;
    flex-direction: row;

    .cardContainer__item__heading {
      flex: 30%;
      padding-left: 5px;
      color: var(--gray-500);
    }

    .cardContainer__item__content {
      flex: 70%;
    }
  }
}

/** Default horizontal card design to display post information **/
.postHorizontalCard {
  display: flex;
  border-radius: 8px;
  padding: 10px;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .postHorizontalCard__row {
    display: flex;
    gap: 20px;
    align-items: center;

    .postHorizontalCard__postInfo {
      display: flex;
      flex-direction: column;
      flex: 3;
    }

    .postHorizontalCard__pubDate {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .postHorizontalCard__postType {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .postHorizontalCard__postStatus {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .postHorizontalCard__actions {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #d9d9d9;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .form-wrapper-title {
    font-size: 18px;
  }
  .default-text-input-style {
    font-size: 1rem;
  }
}

.font-small {
  font-size: 12px;
}
@media screen and (max-width: 480px) {
  .font-small {
    font-size: 8px;
  }
}

/*************************************************************************************** Override Ant Components Design **/
.ant-picker .ant-picker-input > input {
  line-height: 19px;
  font-size: 12px;
}
@media only screen and (max-width: 480px) {
  .ant-picker-dropdown {
    margin-left: auto !important;
    margin-right: auto !important;
    /* left: 20px !important;
    right: 0px !important;
    top: 100px !important; */
  }
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--green-700);
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-k7429z).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-k7429z).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background: var(--green-600);
}

.ant-btn-default {
  padding: 8px 30px;
  gap: 10px;
  border-radius: 6px;
  height: auto;
}

@media screen and (max-width: 480px) {
  .ant-btn-primary {
    padding: 6px 12px;
  }
}

.ant-btn-primary {
  background-color: var(--green-600);
  border-color: var(--green-600);
  padding: 8px 30px;
  gap: 10px;
  border-radius: 6px;
  height: auto;
}
.ant-btn-default span,
.ant-btn-primary span {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.ant-btn-primary:not(:disabled):active {
  background-color: var(--success-600);
  border-color: var(--success-600);
}
.ant-btn-primary:not(:disabled):hover {
  background-color: var(--success-600);
  border-color: var(--success-600);
}
.ant-btn-primary:disabled {
  color: white;
  background-color: var(--gray-200);
  border-color: var(--gray-200);
}

.ant-btn-primary.ant-btn-dangerous {
  background-color: var(--danger-600);
  border-color: var(--danger-600);
}
.ant-btn-primary.ant-btn-dangerous:not(:disabled):hover,
.ant-btn-primary.ant-btn-dangerous:not(:disabled):active {
  background-color: var(--danger-700);
  border-color: var(--danger-700);
}

.ant-btn-default:not(:disabled):hover,
.ant-btn-default:not(:disabled):active {
  color: var(--green-700);
  border-color: var(--green-700);
}
/* crawlerModalWrap */
.crawlerModalWrap .ant-modal .ant-modal-close {
  width: 32px;
  height: 32px;
  top: 32px;
  inset-inline-end: 32px;
}
.crawlerModalWrap .ant-modal .ant-modal-content {
  padding: 26px 32px 32px;
}

/* confirmWrap */
.confirmWrap .ant-modal .ant-modal-content {
  padding: 32px 24px;
}

/* loadingModalWrap */
.loadingModalWrap .ant-modal .ant-modal-content {
  padding: 32px 24px 28px;
}

/* list */
.ant-list-split .ant-list-item {
  border-block-end: 1px solid var(--gray-400);
  transition: all 0.2s ease-in-out;
}
.ant-list .ant-list-item .ant-list-item-meta {
  display: flex;
  align-items: stretch;
  height: 100%;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 12px;
  margin-inline-end: 12px;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-content {
  flex: 1;
}

/* tags */
.ant-tag {
  display: inline-flex;
  align-items: center;
}

/* hack to only show prev and next buttons to support cursor based pagination */
.ant-pagination-item,
.ant-pagination-jump-next,
.ant-pagination-jump-prev {
  display: none !important;
}

/* table */
.wyskTable > thead > tr,
.postList > thead > tr {
  border-color: var(--gray-300);
}

.wyskTable.ant-table-wrapper .ant-table-thead > tr > th,
.wyskTable.ant-table-wrapper .ant-table-tbody > tr > th,
.wyskTable.ant-table-wrapper .ant-table-tbody > tr > td,
.wyskTable.ant-table-wrapper tfoot > tr > th,
.wyskTable.ant-table-wrapper tfoot > tr > td,
.postList.ant-table-wrapper .ant-table-thead > tr > th,
.postList.ant-table-wrapper .ant-table-tbody > tr > th,
.postList.ant-table-wrapper .ant-table-tbody > tr > td,
.postList.ant-table-wrapper tfoot > tr > th,
.postList.ant-table-wrapper tfoot > tr > td {
  padding: 30px 16px;
}
.wyskTable.ant-table-wrapper .ant-table-thead > tr > th,
.wyskTable.ant-table-wrapper .ant-table-thead > tr > td,
.postList.ant-table-wrapper .ant-table-thead > tr > th,
.postList.ant-table-wrapper .ant-table-thead > tr > td {
  background: transparent;
  color: var(--gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.28px;
  padding-bottom: 4px;
}
.wyskTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.wyskTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.postList.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.postList.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: unset;
}
/*** Override card css used in timeline ***/
.ant-card-body {
  padding: 8px !important;
}

.ant-card-head {
  padding: 8px !important;
}

.custom-slider > .ant-slider .ant-slider-handle::after {
  border-radius: 0 !important;
}

/*** Override dropdown css used in posts ***/
.wysk-dropdown .ant-dropdown-menu {
  padding: 4px 0;
  margin-top: 8px;
  box-shadow: unset;
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  overflow: hidden;
}
.wysk-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 4px 16px;
  border-radius: 0;
}
.wysk-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: var(--green-100);
  color: var(--green-600) !important;
}

@media screen and (max-width: 480px) {
  .ant-picker-panels {
    flex-direction: column;
  }
  .ant-picker-range-wrapper {
    top: 50px !important;
    left: 50px !important;
  }
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > :nth-child(2) {
    display: none !important;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

/******************************************************** Override Algolia React Instant Search Satellite theme*/
.ais-SearchBox-input {
  border: 1px solid #00b800 !important;
  border-radius: 8px !important;
}

@media only screen and (max-width: 480px) {
  .ais-SearchBox-input {
    font-size: 1rem;
    width: 180px;
  }
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  color: #00b800 !important;
  font-weight: bold !important;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
  padding: 1.2rem !important;
}

.ais-MenuSelect-select,
.ais-NumericSelector-select,
.ais-HitsPerPage-select,
.ais-ResultsPerPage-select,
.ais-SortBy-select {
  border: #00b800 !important;
}

.ais-Pagination-link {
  border: 1px solid #eeeeee !important;
}

.ais-Pagination-list {
  display: flex !important;
  justify-content: flex-end !important;
  padding-bottom: 30px !important;
}

.ais-Hits-item:first-of-type,
.ais-InfiniteHits-item:first-of-type {
  border-radius: 8px 8px 0 0 !important;
}

.ais-RefinementList-checkbox {
  box-shadow: none !important;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox,
.ais-GeoSearch-input:checked {
  color: #08ae36 !important;
}

.ais-RefinementList-count {
  border: none !important;
}

.ais-RefinementList-checkbox,
.ais-GeoSearch-input {
  color: var(--base-black) !important;
}

.searchRefreshContainer {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

/*************************************************************************************** Override Ant Tabs **/
.ant-tabs {
  width: 70%;
}

.ant-tabs-tab-btn {
  font-weight: bold;
}

.ant-tabs-nav {
  align-self: center;
}
